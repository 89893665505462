<template>
    <div class="main">
        <div class="box">
            <h1 class="title-about" v-html="$t('identity.title')"></h1>
            <div class="row">
                <div class="col-50">
                    <img src="./../assets/identity.png" alt="identity" class="identity-img" />
                </div>
                <div class="col-50">
                    <p v-html="$t('identity.text1')"></p>
                    <p v-html="$t('identity.text2')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "Identity",
}
</script>

<style scoped lang="scss">

.identity-img {
    max-width: 90%;
}

p{
    font-size: 18px;
}

.row {
    align-items: center;
    margin-top:80px;
}

@media screen and (max-width:768px) {
    .identity-img {
        margin-bottom:20px;
    }
}

</style>